html, body {
  font-size: small;
  background-color: #f0f0f0;
}

.app-bar {
  background-color: aliceblue;
  height: 64px;
  /* line-height: 20px; */
}

.search-form {
  border: 1px solid #CCC;
  border-radius: 28px;
  height: 36px;
  line-height: 36px;
  margin-top: 11px;
  background-color: #FFF;
}

.search-form input {
  box-sizing: border-box;
  width: 100%;
  padding: 0 14px;
  height: 30px;
  border: none;
  outline: none;
  background-color: transparent;
}

.search-form button {
  box-sizing: border-box;
  width: 100%;
  padding: 0 14px;
  height: 30px;
  border: none;
  outline: none;
  background-color: transparent;
}

.top-tiny-bar {
  background-color: #322975;
  height: 60px;
  line-height: 60px;
}

.top-tiny-bar a {
  color: rgb(199, 210, 247);
  padding: 5px 0;
}

.top-tiny-bar a:hover {
  color: #FFF;
}

.search-result {
  position: absolute;
  height: 40vh;
  overflow: auto;
  border: 1px solid #CCC;
  border-radius: 20px;
  left: 20px;
  right: 20px;
  margin-top: 5px;
  background-color: #FFF;
  z-index: 1;
}

.slick-arrow.slick-next::before {
  font-size: 50px !important;
}

.slick-arrow.slick-prev::before {
  font-size: 50px !important;
}

a {
  text-decoration: none;
}

a.text-white, .text-white {
  color: #FFF;
}

/* .banner {
  background-image: url(../src/assets/svgs/banner.svg);
  background-size: cover;
  background-position: center;
  height: 300px;
} */

.sm-show {
  display: none;
}

@media only screen and (max-width: 900px) {
  .sm-show {
    display: block;
  }
  .sm-hide {
    display: none;
  }
}